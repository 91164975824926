import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

(() => {
  gsap.registerPlugin(ScrollTrigger);

  const titles = gsap.utils.toArray(".text-transform-left");

  setTimeout(() => {
    for (const title of titles) {
      gsap.to(title, {
        scrollTrigger: {
          trigger: title,
          // markers: true,
          scrub: true,
          // pin: true,
          toggleActions: "restart pause reverse pause",
        },
        x: "-=50vw",
      });
    }
  }, 1000);

  console.log("Hi there! This website was created by YungPanda");
})();
